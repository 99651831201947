import React, { FC, ReactNode } from 'react'

import cn from 'classnames'

import classes from './helper-message.module.scss'
import { Icon } from '../icon/icon'

/***
 *  Design system: https://www.figma.com/file/JRJ2xtZ27FkTiR6GEvt0yuDd/%E2%9D%96-SMARTY-Design-System?node-id=11313%3A72853&t=qtoux0UV0tNxeANs-0
 */

export type HelperMessageType =
  | 'info'
  | 'warning'
  | 'alert'
  | 'error'
  | 'success'

export type HelperMessageClasses = typeof classes

interface HelperMessageProps {
  type: HelperMessageType
  children: (contentClass: HelperMessageClasses) => ReactNode
  rootClass?: string
}

export const HelperMessage: FC<HelperMessageProps> = ({
  type,
  children,
  rootClass,
}) => (
  <div className={cn(classes.root, classes[type], rootClass)}>
    <Icon name={`${type}-icon`} className={classes.icon} />
    {children(classes)}
  </div>
)
