import React from 'react'

import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import PageHead from '../../../components/PageHead'
import {
  AltPara,
  BreadCrumbs,
  Card,
  Container,
  Desktop,
  Heading,
  ListItem,
  Mobile,
  SubHeading,
  TopFlag,
} from '../../../components/styles/Roaming'
import SiteContainer from '../../../components/styles/SiteContainer'
import withApp from '../../../components/withApp/withApp'
import EuropeSVG from '../../../static/images/europe.svg'

function Europe({ pageContext: { display_country, iso_country_code } }) {
  return (
    <SiteContainer>
      <BreadCrumbs>
        <Desktop>
          <Link to="/roaming">Roaming & international</Link>
          <span style={{ padding: '0 10px' }}>{'>'}</span>
          <Link to="/roaming/europe">Europe</Link>
          <span style={{ padding: '0 10px' }}>{'>'}</span> {display_country}
        </Desktop>
        <Mobile>
          {'< '}
          <Link to="/roaming/europe">Select another European country</Link>
        </Mobile>
      </BreadCrumbs>
      <TopFlag
        src={`/assets/images/flags/${iso_country_code.toLowerCase()}.png`}
        alt={iso_country_code}
      />
      <Heading>Using SMARTY in {display_country}</Heading>
      <Container
        style={{ marginBottom: '60px' }}
        bgColor="#ffd67c"
        padding="15px"
      >
        <div className="inner-padding">
          <img src={EuropeSVG} alt="Europe" />
          <SubHeading>
            Good news. {display_country} is included when roaming within the EU.
          </SubHeading>
          <SubHeading>Use your SMARTY plan anywhere in the EU.</SubHeading>
          <ul>
            <ListItem>
              If your plan includes calls and texts, they work just like home.
              They’re unlimited with no extra fees.
            </ListItem>
            <ListItem>
              Subject to our fair use limit, you can use up to 12GB of data each
              month out of your monthly allowance. If you need more data, you
              can buy a data add-on.
            </ListItem>
            <ListItem>No hidden charges. No extra costs.</ListItem>
            <ListItem>
              <a
                href="/priceguide"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="See all prices .PDF (253KB) link opens in a new window"
              >
                Please see our Price Guide for more information on our Fair Use
                Policy.
              </a>
            </ListItem>
          </ul>
          <AltPara>
            Need to call or text non-UK or Internationally from{' '}
            {display_country}?
          </AltPara>
          <a
            href="/priceguide"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="See all prices .PDF (253KB) link opens in a new window"
          >
            See all prices
          </a>
        </div>
        <Card>
          <h2 className="card-heading">Before you go</h2>
          <h2>Phone settings update required</h2>
          <AltPara>
            Your SMARTY SIM won’t work abroad unless you make a one-off change
            to your phone settings.
          </AltPara>
          <a href="/roaming-setup">Set up your phone now</a>
        </Card>
      </Container>
    </SiteContainer>
  )
}

Europe.propTypes = {
  pageContext: {
    display_country: PropTypes.string,
    iso_country_code: PropTypes.string,
  },
}

export default withApp(Europe)

export const Head = ({ pageContext }) => (
  <PageHead title={`Using SMARTY in ${pageContext.display_country}`} />
)

Head.propTypes = {
  pageContext: PropTypes.object,
}
