import URLSearchParams from '@ungap/url-search-params'

import { createCookie } from './cookie'

const naturalSearch = 'naturalSearch'
const organicSocialMedia = 'organicSocialMedia'

const getReferrer = ({ referrer }) => {
  const urlObject = new URL(referrer)
  const hostname = urlObject.hostname || ''

  if (hostname.includes('google')) return naturalSearch
  if (hostname.includes('bing')) return naturalSearch
  if (hostname.includes('yahoo')) return naturalSearch
  if (hostname.includes('duckduckgo')) return naturalSearch
  if (hostname.includes('facebook')) return organicSocialMedia
  if (hostname.includes('twitter')) return organicSocialMedia
  if (hostname.includes('instagram')) return organicSocialMedia
  if (hostname.includes('youtube')) return organicSocialMedia
  return null
}

export const getAffiliate = ({ urlSearch, referrer }) => {
  try {
    const searchParams = new URLSearchParams(urlSearch)
    const gclid = searchParams.get('gclid')
    const utm_campaign = searchParams.get('utm_campaign')
    const utm_source = searchParams.get('utm_source')
    const utm_medium = searchParams.get('utm_medium')

    if (gclid) return 'paidGenericSearch'
    if (utm_medium === 'paid') return 'paidSocialMedia'
    if (utm_campaign === '895036311') return 'paidSearchBrand'
    if (utm_medium === 'affiliates' && utm_campaign !== 'awin')
      return 'affiliate'
    if (utm_medium === 'affiliates' && utm_campaign === 'awin')
      return 'affiliateAwin'
    if (
      utm_source === 'adroll' ||
      (utm_campaign && utm_campaign.includes('TU')) ||
      (utm_campaign && utm_campaign.includes('r.ar1d.net'))
    )
      return 'adroll'
    if (referrer && getReferrer({ referrer }) === naturalSearch)
      return naturalSearch
    if (referrer && getReferrer({ referrer }) === organicSocialMedia)
      return organicSocialMedia

    return null
  } catch (e) {
    // TODO: Datadog tracking in S81
    return null
  }
}

export const setAffiliateCookiesFromUrl = (searchString) => {
  const urlParams = new URLSearchParams(searchString)

  // AWIN:
  const awcTracking = urlParams.get('awc')
  if (awcTracking) {
    createCookie({ name: 'awc', value: awcTracking, days: 30 })
  }
}
