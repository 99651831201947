import React, { ReactNode, FC, useState } from 'react'

import cn from 'classnames'

import classes from './accordion.module.scss'
import { Icon } from '../icon/icon'
import { Typography } from '../typography/typography'

interface AccordionProps {
  children?: ReactNode
  dataTestId?: string
  title: string
  className?: string
}

export const Accordion: FC<AccordionProps> = ({
  title,
  children,
  dataTestId,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div data-test={dataTestId} className={cn(classes.accordion, className)}>
      <div onClick={() => setIsOpen(!isOpen)} className={classes.header}>
        <Typography className={classes.title} variant="Kollektif/Small">
          {title}
        </Typography>
        <Icon
          className={cn(classes.chevron, { [classes.closed]: !isOpen })}
          name="chevron-up"
        />
      </div>
      {isOpen && (
        <Typography
          className={classes.content}
          variant="Kollektif/ExtraSmall"
          component="div"
        >
          {children}
        </Typography>
      )}
    </div>
  )
}
