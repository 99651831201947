import React from 'react'

import VoucherIcon from './../../static/images/raf/voucher.svg'
import { TERMS_LINK } from '../../global.constants'

export const Content = {
  id: 'raf-hero-banner',
  title: `Share the love and get rewards`,
  text: (
    <>
      Refer your friends to SMARTY and when they renew for a second month,
      you’ll both get a choice of a gift card worth up to £20.{' '}
      <a href="/refer-a-friend" data-testid="raf-link">
        {' '}
        Find out more.{' '}
      </a>
    </>
  ),
  textSmall: (
    <>
      By using this link, you agree for us to share your details with our
      partner, Buyapowa, to fulfil your reward. See{' '}
      <a href={TERMS_LINK} target="_blank" rel="noopener noreferrer">
        terms
      </a>{' '}
      for more details.
    </>
  ),
  icon: VoucherIcon,
  cta: 'Copy referral link',
}
