import React, { FC } from 'react'

import { GenericBannerItem } from '@smarty-nx/contentful'

import classes from './card.module.scss'
import { ContentfulProcessor } from '../'

export interface CardProps {
  banner: GenericBannerItem
}

export const Card: FC<CardProps> = ({ banner }) => (
  <div className={classes.card}>
    <img
      className={classes.image}
      src={banner?.image?.url}
      alt={banner?.image?.title}
    />
    <div className={classes.textBlock}>
      <h3 className={classes.sectionHeading}>{banner?.title}</h3>
      <p className={classes.sectionDescription}>{banner?.description}</p>
      <ContentfulProcessor
        content={banner?.link || ''}
        components={{
          a({ node: _, children, ...props }) {
            return (
              <a
                rel="noopener noreferrer"
                className={classes.ctaLink}
                {...props}
              >
                {children}
              </a>
            )
          },
        }}
      />
    </div>
  </div>
)
