import React from 'react'

import { getPlanBySlug } from '@smarty-nx/contentful'
import cn from 'classnames'
import Countdown from 'react-countdown'

import { countdownBannerContent } from './CountdownBanner.content'
import classes from './CountdownBanner.module.scss'
import { CountdownBannerProps } from './CountdownBanner.types'

const CountdownBanner = ({
  planName,
  planSlug,
  voucherExpiryDate,
}: CountdownBannerProps) => {
  const planMeta = getPlanBySlug(planSlug)
  if (!planMeta) {
    return null
  }

  const { slug, planColor } = planMeta

  return (
    <a className={classes.root} href={`/order/${slug}`}>
      <div
        className={cn(
          classes.wrapper,
          classes[`backgroundColour${planColor?.theme.replace('/', '')}`]
        )}
      >
        <div className={classes.countdownColumn}>
          <p className={classes.text}>{planName} offer ends in </p>
        </div>
        <div className={cn(classes.countdownColumn, classes.withNumbers)}>
          {countdownBannerContent.durations.map((d) => (
            <div className={classes.time} key={d.time}>
              <Countdown
                date={voucherExpiryDate}
                renderer={(props) => (
                  <>
                    <p className={classes.text}>{props[d.time]}</p>
                    <p className={classes.smallText}>{d.text_to_display}</p>
                  </>
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </a>
  )
}

export default CountdownBanner
