/* eslint-disable */

import { CONFIG, FEATURES } from '../config'
import { initIntercom } from '@smarty-nx/code-utils'

const intercom = initIntercom()

if (CONFIG.SMARTY_ENV !== 'production') {
  console.info(
    `Running smarty-marketing in non-production mode (${CONFIG.SMARTY_ENV}) with app_id ${CONFIG.INTERCOM_APP_ID}`
  )
}

export const boot = (props) => {
  intercom.boot({
    postponed: FEATURES.IS_INTERCOM_POSTPONED,
    app_id: CONFIG.INTERCOM_APP_ID,
    custom_launcher_selector: '#intercom_launcher',
    ...props,
  })
}

export const shutdown = intercom.shutdown

export const update = intercom.update
