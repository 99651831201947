import pages from '../../data/pages.json'

export const getPageByPath = (pathName: string) => {
  return pages.find(({ path }) => pathName === path)
}

export const getFamilyPlanInvitationPage = () =>
  getPageByPath('/groups/family-plan-invitation')

export const getInvitationSentPage = () =>
  getPageByPath('/groups/invitation-sent')

export const getInvalidInvitationPage = () =>
  getPageByPath('/groups/invalid-invitation')

export const getInvitationErrorPage = () =>
  getPageByPath('/groups/invite-error')
