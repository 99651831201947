import { useEffect, useState } from 'react'

export const useInMediaRange = (min = 0, max = 759) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mediaWatcher = window.matchMedia(
      `screen and (min-width: ${min}px) and (max-width: ${max}px)`
    )

    setIsMobile(mediaWatcher.matches)

    function update(e: MediaQueryListEvent) {
      setIsMobile(e.matches)
    }

    mediaWatcher.addEventListener('change', update)

    return function cleanup() {
      mediaWatcher.removeEventListener('change', update)
    }
  }, [min, max])

  return isMobile
}
