import React from 'react'

import { Button } from '@smarty-nx/ds'

import classes from './sim-in-hand.module.scss'
import SiteContainer from '../../components/styles/SiteContainer'
import withApp from '../../components/withApp/withApp'
import { CONFIG } from '../../config'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'

const SimInHand = () => {
  return (
    <div className={classes.simInHand}>
      <SiteContainer>
        <div className={classes.darkGreyCard}>
          <h1>Do you have a SMARTY SIM already?</h1>
          <p className="margin--regular kollektif-small">
            This includes SMARTY SIM cards purchased previously, but which have
            not been activated yet.
          </p>
          <div className={classes.actions}>
            <Button
              as={(props) => (
                <a
                  {...props}
                  href={`${
                    CONFIG.SMARTY_ENV === 'localhost'
                      ? CONFIG.DASHBOARD_URL
                      : '/'
                  }check-email-exists`}
                  data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                  data-item-name="social-tariff-no-sim"
                >
                  No
                </a>
              )}
            />
            <Button
              level="secondary"
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              data-item-name="social-tariff-have-sim"
            >
              Yes
            </Button>
          </div>
        </div>
      </SiteContainer>
    </div>
  )
}

export default withApp(SimInHand)
