import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { defaults, typography } = dsTheme

export const Title = styled.h1`
  color: ${colors['Brand/PrimaryBlack']};
  ${typography['Kollektif/Large']};
  margin: 0 0 0.5rem;
`

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${defaults['Buttons/max-width']};

  @media (min-width: ${breakpoints.desktop}px) {
    max-height: 48px;
    max-width: 462px;
    flex-direction: row;
  }

  input {
    @media (min-width: ${breakpoints.desktop}px) {
      margin-right: 8px;
    }
  }
`

export const CtaInput = styled.input`
  ${typography['Kollektif/TinyEmphasis']};
  background-color: ${colors['Brand/PalePrimaryGreen']};
  color: ${colors['UI/DarkGrey']};
  border-radius: 3px;
  border: 0;
  margin-bottom: 0.5rem;
  padding: 0.5rem;

  @media (min-width: ${breakpoints.desktop}px) {
    ${typography['Kollektif/SmallEmphasis']};
    margin-bottom: 0;
    flex: 1;
  }
`

export const RafWrapper = styled.div`
  padding: 1rem;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 1.5rem;
  }

  h3 {
    margin-bottom: 0.5rem;

    @media (min-width: ${breakpoints.desktop}px) {
      ${typography['Kollektif/Large']};
    }
  }

  p {
    ${typography['Kollektif/ExtraSmall']};

    @media (min-width: ${breakpoints.desktop}px) {
      ${typography['Kollektif/Medium']};
    }
  }

  button,
  a {
    max-width: ${defaults['Buttons/max-width']};

    @media (min-width: ${breakpoints.desktop}px) {
      span {
        ${typography['Kollektif/ExtraSmallEmphasis']};
      }
    }
  }
`

export const Para = styled.p`
  color: ${colors['Brand/PrimaryBlack']};
  margin-bottom: 1rem;

  ${RafWrapper} & {
    ${typography['Kollektif/Small']};
  }
`

export const ParaSmall = styled.p`
  color: ${colors['Brand/PrimaryBlack']};
  margin-bottom: 1rem;

  ${RafWrapper} & {
    ${typography['Kollektif/Tiny']};
  }
`

export const RafContainer = styled.div`
  background-color: ${colors['Brand/PrimaryGreen']};
  color: ${colors['Brand/PrimaryBlack']};
  box-shadow: 0 4px 9px 0 #00000040;

  border-radius: ${defaults['Borders/radius-medium']};

  display: flex;
  justify-content: space-between;
  overflow: hidden;
`

export const Angle = styled.div`
  background-color: ${colors['UI/DarkestGrey']};
  min-width: 96px;
  padding: 0.8rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
  clip-path: polygon(55% 0, 100% 0, 100% 100%, 0% 100%);
`

export const Icon = styled.img``
