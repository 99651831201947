import React, { FC, ReactNode, useState } from 'react'

import { useInMediaRange } from '@smarty-nx/code-utils'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'

import classes from './tabs.module.scss'

/***
 * Design system: https://www.figma.com/file/RE9n7PIwHG7A7WHA7kEVhN/WEB-Components?node-id=1011:126561&mode=dev
 * */

interface TabProps {
  label: string
  content: ReactNode
  trackingProps?: Record<string, unknown>
}

interface TabsProps {
  tabs: TabProps[]
  header?: ReactNode
  defaultIndex?: number
  alignment?: 'center' | 'left'
  className?: string
}

export const Tabs: FC<TabsProps> = ({
  tabs,
  defaultIndex = 0,
  header,
  alignment = 'left',
  className,
}) => {
  const isMobile = useInMediaRange()
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(
    defaultIndex >= tabs.length ? 0 : defaultIndex
  )
  const isCenterAligned = swiper?.isLocked && alignment === 'center'

  const onSelect = (index: number) => () => {
    setSelectedIndex(index)
  }

  return (
    <>
      <Swiper
        watchOverflow
        allowTouchMove
        navigation={false}
        slidesPerView="auto"
        onSwiper={setSwiper}
        spaceBetween={isMobile ? 12 : 20}
        className={cn(
          classes.root,
          {
            [classes.centerAligned]: isCenterAligned,
          },
          className
        )}
      >
        {tabs.map(({ label, trackingProps }, index) => (
          <SwiperSlide key={label}>
            <a
              onClick={onSelect(index)}
              className={cn(classes.tab, {
                [classes.selected]: index === selectedIndex,
              })}
              tabIndex={0}
              {...trackingProps}
            >
              {label}
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
      {header}
      {tabs[selectedIndex].content}
    </>
  )
}
